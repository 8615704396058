const LANG_CONFIG = [
  // English
  {
    id: "en",
    icon: "EnFlagIcon",
    name: "English (UK)",
    isDefault: true,
    URIPart: "",
  },
  // Chinese
  {
    id: "cn",
    icon: "CnFlagIcon",
    name: "简体中文",
    URIPart: "/cn",
  },
  // Taiwan
  {
    id: "zh",
    icon: "CnFlagIcon",
    name: "繁體中文",
    URIPart: "/zh",
  },
];

const ARABIC_LANG_ID = "ar";

module.exports = {
  LANG_CONFIG,
  ARABIC_LANG_ID,
  uniqueList: LANG_CONFIG.map(({ id }) => id),
  list: LANG_CONFIG.map(({ id }) => id),
  defaultLangKey: LANG_CONFIG.find(({ isDefault }) => isDefault).id,
};
